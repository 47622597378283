(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

require('../../../modules/modules');

require('../../../templates/templates');

jQuery(function ($) {
  $(window).on('load', function () {
    // remove bugherd on small screens
    if (window.innerWidth < 768) {
      $('#_BH_frame').remove();
    }
  });
});

},{"../../../modules/modules":2,"../../../templates/templates":18}],2:[function(require,module,exports){
'use strict';

require('./molecules/edge-bg/module-edge-bg');

require('./molecules/form-gform/module-form-gform');

require('./molecules/form-hubspot/module-form-hubspot');

require('./molecules/popup/module-popup');

require('./molecules/popup-gallery/module-popup-gallery');

require('./molecules/popup-iframe/module-popup-iframe');

require('./molecules/popup-image/module-popup-image');

require('./molecules/scroll-link/module-scroll-link');

require('./molecules/slider/module-slider');

require('./molecules/table/module-table');

require('./organisms/header/module-header');

require('./organisms/social-share/module-social-share');

require('./organisms/01-hero/module-01-hero');

require('./organisms/03-brackets/module-03-brackets');

require('./organisms/04-text-media-split/module-04-text-media-split');

},{"./molecules/edge-bg/module-edge-bg":3,"./molecules/form-gform/module-form-gform":4,"./molecules/form-hubspot/module-form-hubspot":5,"./molecules/popup-gallery/module-popup-gallery":6,"./molecules/popup-iframe/module-popup-iframe":7,"./molecules/popup-image/module-popup-image":8,"./molecules/popup/module-popup":9,"./molecules/scroll-link/module-scroll-link":10,"./molecules/slider/module-slider":11,"./molecules/table/module-table":12,"./organisms/01-hero/module-01-hero":13,"./organisms/03-brackets/module-03-brackets":14,"./organisms/04-text-media-split/module-04-text-media-split":15,"./organisms/header/module-header":16,"./organisms/social-share/module-social-share":17}],3:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $(document).ready(function () {
    resizeEdgeBG();
  });
  $(window).resize(function () {
    resizeEdgeBG();
  });
});

function resizeEdgeBG() {
  jQuery(function ($) {
    if ($('.edge-bg').length) {

      $('.edge-bg').each(function () {

        if (window.innerWidth >= 768) {

          $(this).removeClass('is-resized');
          $(this).find('.edge-bg-back').css('position', 'absolute');
          var h = $(this).find('.edge-bg-back-left, .edge-bg-back-right, .edge-bg-front-left, .edge-bg-front-right').map(function () {
            return $(this).outerHeight();
          }).get();
          var mh = Math.max.apply(null, h);
          $(this).css('height', mh);
          $(this).addClass('is-resized');

          $(this).find('.edge-bg-back .bg-image').css('height', mh);

          var left;
          if ($(this).find('.edge-bg-front-left[class*="push"]').length) {
            left = $(window).width() - $(this).find('.edge-bg-back-left').offset().left;
          } else {
            left = $(this).find('.edge-bg-front-left').offset().left + $(this).find('.edge-bg-front-left').outerWidth();
          }

          var right = $(window).width() - left;

          $(this).find('.edge-bg-back-left').width(left);
          $(this).find('.edge-bg-back-right').width(right);
        } else {

          $(this).css('height', 'auto');
          $(this).find('.edge-bg-back').css('position', 'relative');
          $(this).find('.edge-bg-back-left').css({ 'width': '100%', 'height': 300 });
        }
      });
    }
  });
}

},{}],4:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $(document).ready(function () {
    $('.floating .ginput_container input, .floating .ginput_container .textarea').each(function () {
      if ($(this).val() !== '') {
        $(this).parents('.ginput_container').siblings('label').addClass('focused');
      }
    });

    $('.floating .ginput_container input, .floating .ginput_container .textarea').on('keyup focus blur', function () {

      if ($(this).val().length > 0) {
        $(this).parents('.ginput_container').siblings('label').addClass('focused');
      } else {
        $(this).parents('.ginput_container').siblings('label').removeClass('focused');
      }
    });

    $('.floating .ginput_container input, .floating .ginput_container .textarea').blur(function () {
      if ($(this).val() === '') {
        $(this).parents('.gfield').addClass('warning');
      } else {
        $(this).parents('.gfield').removeClass('warning');
      }
    });
  });

  $(window).on('load', function () {
    $('.gform_wrapper .ginput_container_checkbox').each(function () {
      $(this).parents('.gfield').addClass('gf-checkbox');
    });
    $('.gform_wrapper .ginput_container_radio').each(function () {
      $(this).parents('.gfield').addClass('gf-radio');
    });
    $('.gform_wrapper .ginput_container_fileupload').each(function () {
      $(this).parents('.gfield').addClass('gf-file');
    });
    $('.gform_wrapper .ginput_container_select').each(function () {
      $(this).parents('.gfield').addClass('gf-select');
    });
  });
});

},{}],5:[function(require,module,exports){
'use strict';

jQuery(function ($) {

  var inputs = '.hbspt-form input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]):visible, .hbspt-form textarea:visible';

  $(document).ready(function () {
    $(document).on('blur', inputs, function () {
      if ($(this).val() === '') {
        $(this).parents('.hs-form-field').addClass('warning');
      } else {
        $(this).parents('.hs-form-field').removeClass('warning');
      }
    });

    $(document).on('keyup focus blur', inputs, function () {
      if ($(this).val().length > 0) {
        $(this).parents('.hs-form-field').find('label:first').addClass('focused');
      } else {
        $(this).parents('.hs-form-field').find('label:first').removeClass('focused');
      }
    });

    $(document).on('click', '.hbspt-form .inputs-list li', function (e) {
      e.preventDefault();
      e.stopPropagation();
      var checkbox = $(this).find('input[type="checkbox"]'),
          radio = $(this).find('input[type="radio"]');
      $('.hbspt-form .inputs-list .hs-form-radio').removeClass('is-checked');
      $(this).toggleClass('is-checked');
      if (checkbox.prop('checked') === true) {
        checkbox.prop('checked', false).change();
      } else {
        checkbox.prop('checked', true).change();
      }
      radio.prop('checked', true).change();
    });
  });

  $(window).on('load', function () {
    $(inputs).each(function () {
      if ($(this).val() !== '') {
        $(this).parents('.hs-form-field').find('label:first').addClass('focused');
      }
    });
    $('.hbspt-form select:visible').each(function () {
      $(this).parents('.hs-form-field').addClass('select');
    });
    $('.hbspt-form .inputs-list:visible').each(function () {
      $(this).parents('.hs-form-field').addClass('list');
    });
    $('.hbspt-form .hs-dateinput:visible').each(function () {
      $(this).parents('.hs-form-field').addClass('date');
    });
    $('.hbspt-form [type="file"]:visible').each(function () {
      $(this).parents('.hs-form-field').addClass('file');
    });
    $('.hbspt-form .inputs-list').each(function () {
      if ($(this).find('.hs-form-booleancheckbox').length) {
        $(this).removeClass('inputs-list').addClass('boolean');
      }
    });
  });
});

},{}],6:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $('.popup-gallery').each(function () {
    $(this).magnificPopup({
      delegate: '.popup-gallery-link',
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1]
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function titleSrc(item) {
          return item.el.attr('title');
        }
      }
    });
  });
});

},{}],7:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $('.popup-iframe-link').magnificPopup({
    type: 'iframe',
    midClick: true,
    iframe: {
      patterns: {
        youtube: {
          index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

          id: 'v=', // String that splits URL in a two parts, second part should be %id%
          // Or null - full URL will be returned
          // Or a function that should return %id%, for example:
          // id: function(url) { return 'parsed id'; }

          src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
        },
        vimeo: {
          index: 'vimeo.com/',
          id: '/',
          src: '//player.vimeo.com/video/%id%?autoplay=1'
        },
        wistia: {
          index: 'wistia.com/',
          id: '/medias/',
          src: '//fast.wistia.net/embed/iframe/%id%?videoFoam=true&autoplay=true'
        },
        gmaps: {
          index: '//maps.google.',
          src: '%id%&output=embed'
        }
      }
    }
  });
});

},{}],8:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $('.popup-image-link').magnificPopup({
    type: 'image',
    midClick: true
  });
});

},{}],9:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $('.popup-link').magnificPopup({
    type: 'inline',
    midClick: true
  });
});

},{}],10:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $('.scroll-link').click(function (e) {
    e.preventDefault();
    $(this).blur();
    var string = $(this).attr('href').split('#')[1];
    $('#' + string).velocity('scroll', 400);
  });
});

},{}],11:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $(document).ready(function () {
    $('.m-slider').slick();
  });
});

},{}],12:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $(document).ready(function () {
    $('.m-table').trigger('enhance.tablesaw');
  });
});

},{}],13:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $(document).ready(function () {
    var slider = $('.m-hero');
    slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      var currentVideo = $('[data-slick-index="' + currentSlide + '"]').find('video'),
          nextVideo = $('[data-slick-index="' + nextSlide + '"]').find('video');
      if (currentVideo.length) {
        currentVideo.get(0).pause();
      }
      if (nextVideo.length) {
        nextVideo.get(0).play();
      }
    });
    slider.on('init reInit', function () {
      var currentVideo = $('[data-slick-index="0"]').find('video');
      if (currentVideo.length) {
        currentVideo.get(0).play();
      }
    });
    slider.slick({
      infinite: false,
      responsive: [{
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      }]
    });
  });
});

},{}],14:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $(document).ready(function () {
    buttonsOnBottom();
    bracketsSlick();
  });
  $(window).on('load', function () {
    bracketImageHeight();
    buttonsOnBottom();
  });
  $(window).resize(function () {
    bracketImageHeight();
    buttonsOnBottom();
    bracketsSlick();
  });

  function bracketImageHeight() {
    if ($('.m-brackets-bracket .image-cover').length) {
      var imageWidth = $('.m-brackets-bracket .image-cover').width(),
          images = $('.m-brackets-bracket .image-cover');
      if (window.innerWidth >= 768) {
        images.height(imageWidth);
      }
    }
  }

  function buttonsOnBottom() {
    $('.m-brackets').equalHeights();
  }

  function bracketsSlick() {
    if (window.innerWidth < 768) {
      $('.m-brackets-bracket-row:not(.slick-initialized)').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        adaptiveHeight: true
      });
    } else {
      $('.m-brackets-bracket-row.slick-initialized').slick('unslick');
    }
  }
});

},{}],15:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $(document).ready(function () {
    textMediaSlick();
    resizeTextMedia();
  });
  $(window).on('load', function () {
    $('.m-text-media-split-content').css('opacity', '1');
    resizeTextMedia();
  });
  $(window).resize(function () {
    textMediaSlick();
    resizeTextMedia();
  });
});

function resizeTextMedia() {
  jQuery(function ($) {
    $('.m-text-media-split').equalHeights();
  });
}

function textMediaSlick() {
  jQuery(function ($) {
    $('.m-text-media-split').each(function () {
      if ($(this).find('.m-text-media-split-gallery .m-text-media-split-gallery-image').length > 1) {
        $(this).find('.m-text-media-split-gallery').not('.slick-initialized').slick({
          slidesToShow: 1,
          slidestoScroll: 1,
          dots: true,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 3000
        });
      }
    });
  });
}

},{}],16:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $(document).ready(function () {
    $('.m-header-nav-toggle').click(function (e) {
      e.preventDefault();
      if ($(this).data('is-open') === false) {
        $('.m-header-nav-nav').velocity('slideDown', {
          duration: 400
        });
        $(this).data('is-open', true);
      } else {
        $('.m-header-nav-nav').velocity('slideUp', {
          duration: 400
        });
        $(this).data('is-open', false);
      }
      $(this).toggleClass('is-active');
    });
  });
});

},{}],17:[function(require,module,exports){
'use strict';

jQuery(function ($) {
  $(document).on('click', '.m-social-share a', function (e) {
    e.preventDefault();
    window.open($(this).data('link'), 'ShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0').focus();
    return false;
  });
});

},{}],18:[function(require,module,exports){
"use strict";

},{}]},{},[1]);
